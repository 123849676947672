import React from "react";
import i18next from "i18next";
import {withTranslation} from "react-i18next";
import HeadLogo from "../assets/img/HeadLogo";

const navigation = [
	"service", "contact"
]

class Navigation extends React.Component {
	render() {
		const {t, scrollDown, visible} = this.props;
		return (
			<div className={"header"+(this.props.scrolled?" scrolled":"")}>
				<div className="container">
					<div className="navigation">
						<p className="headlogo">
							<HeadLogo onClick={() => scrollDown("welcome")}/>
						</p>
						<div>
							<ul>
								{navigation.map((nav, i) => <li key={"nav-"+i} onClick={() => scrollDown(nav)} className={visible === nav ? "active":""}><span className="nav draw meet">{t(nav)}</span></li>)}
							</ul>
							<div className="divider"/>
							<ul className="languages">
								<li className={i18next.language === "de"?"active":""} onClick={() => i18next.changeLanguage("de")}>de</li>
								<li className={i18next.language === "en"?"active":""} onClick={() => i18next.changeLanguage("en")}>en</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Navigation);
