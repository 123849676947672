import React from "react";
import {withTranslation} from "react-i18next";
import {loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha} from 'react-simple-captcha';
import {MdRefresh, MdMail, MdPublic, MdBusiness} from "react-icons/md";
import axios from 'axios';
import images from "../assets/img";
import Privacy from "./Privacy";
import TC from "./TC";

const API_PATH = './sendmail/sendmail.php';

const fields = {
	name: {
		type: "text",
		value: ""
	},
	email: {
		type: "text",
		value: ""
	},
	subject: {
		type: "text",
		value: ""
	},
	phone: {
		type: "text",
		value: ""
	},
	message: {
		type: "textarea",
		value: ""
	},
	captcha: {
		type: "captcha",
		value: ""
	},
	datapolicy: {
		type: "checkbox",
		value: ""
	}
}

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {...fields},
			message: false,
			contactFields: {},
			successful: false,
			privacy: false,
			tc: false,
			footer: false,
			imp: false
		}
	}
	
	loadCaptcha = () => {
		loadCaptchaEnginge(6, "#999", "white");
	}
	
	componentDidMount() {
		this.loadCaptcha();
		if (!this.state.imp) {
			axios.get("https://api.m8werk.de?imp").then(res => {
				if (res && res.data) {
					this.setState({
						imp: res.data
					})
				}
			})
		}
		if (!this.state.footer) {
			axios.get("https://api.m8werk.de?footer=Nautilus%20Investment%20AG").then(res => {
				if (res && res.data) {
					this.setState({
						footer: res.data
					})
				}
			})
		}
	}
	
	handleChange(e,
		field) {
		this.setState({
			fields: {
				...this.state.fields,
				[field]: {
					...this.state.fields[field],
					value: e.target.value
				}
			}
		})
	}
	
	doSubmit = () => {
		const {fields} = this.state;
		const contactFields = {};
		const keys = Object.keys(fields);
		for (let i = 0; i < keys.length; i++) {
			if (fields[keys[i]].value === "") {
				this.setState({
					message: "fill-all-fields",
					successful: false
				})
				return false;
			}
			contactFields[keys[i]] = fields[keys[i]].value;
		}
		if (validateCaptcha(this.state.fields.captcha.value) === true) {
			this.loadCaptcha();
			axios({
				method: 'post',
				url: `${API_PATH}`,
				headers: {'content-type': 'application/json'},
				data: {contactFields: contactFields}
			}).then(result => {
				this.setState({
					message: result.data.sent,
					successful: true
				})
			}).catch(error =>
				this.setState({message: error.message, successful: false})
			);
		} else {
			this.setState({
				message: "captcha-no-match",
				successful: false
			})
		}
	};
	
	render() {
		const {fields, message, successful, privacy, tc, imp, footer} = this.state;
		const {t} = this.props;
		return (
			<div className="section" id="contact">
				<div className="container">
					<div className="form-wrapper">
						<div className="side">
							<h3>{t('was-tun')}</h3>
							<h4>{t('fill-out')}</h4>
							{
								message && <p className={"message " + (successful ? "success" : "error")}>{t(message)}</p>
							}
							{
								Object.keys(fields).map((field,
									i) => {
									switch (fields[field].type) {
										case "text":
											return (
												<label key={"field-" + i}>
													<span>{t(field)}</span>
													<input type={fields[field].type} value={fields[field].value} onChange={e => this.handleChange(e, field)}/>
												</label>
											)
										case "textarea":
											return (
												<label key={"field-" + i}>
													<span>{t(field)}</span>
													<textarea onChange={e => this.handleChange(e, field)} value={fields[field].value}>{fields[field].value}</textarea>
												</label>
											)
										case "captcha":
											return (
												<label key={"field-" + i}>
													<span>{t('captcha')}</span>
													<div className="captcha">
														<input type="text" value={fields[field].value} onChange={e => this.handleChange(e, field)}/>
														<LoadCanvasTemplateNoReload/>
														<MdRefresh className="refresh" onClick={() => this.loadCaptcha()}/>
													</div>
												</label>
											)
										case "checkbox":
											return (
												<label key={"field-" + i} className="checkbox">
													<input type={fields[field].type} value={fields[field].value} onChange={e => this.handleChange(e, field)}/>
													<span>{t(field)}</span>
												</label>
											)
										default:
											return ("")
									}
								})
							}
							<button onClick={() => this.doSubmit()}>{t('send')}</button>
						</div>
						<div className="side">
							<h2>{t('contact')}</h2>
							<p>{t('get-in-touch')}</p>
							<hr/>
							<div className="side-wrapper">
								<div className="side">
									<div className="block"><p><b>Nautilus Investment AG</b></p>
										<p>{imp.street}</p>
										<p>{imp.city}</p></div>
									<div className="block"><p><b>Vorstand</b></p>
										<p>Sukhwinder Singh</p>
										<p>{imp.name}</p></div>
									<div className="block"><p><b>Aufsichtsrat</b></p>
										<p>Kulwant Kaur</p></div>
								</div>
								<div className="side">
									<div className="block">
										<p><MdMail/> {imp.email}</p>
										<p><MdPublic/> {imp.web}</p>
										<p><MdBusiness/> {imp.id}</p>
									</div>
									<div className="block">
									</div>
								</div>
							</div>
							<hr/>
							<div className="partners">
								<div><img src={images.m8werk} alt={"m8werk group logo"}/></div>
								<div><img src={images.consulting} alt={"m8werk consulting logo"}/></div>
								<div><img src={images.ander5} alt={"ander5 logo"}/></div>
								<div><img src={images.elapporate} alt={"elapporate logo"}/></div>
								<div><img src={images.immosaar} alt={"immosaar logo"}/></div>
								<div><img src={images.turnworks} alt={"turnworks logo"}/></div>
							</div>
							<hr/>
							<Privacy show={privacy} close={() => this.setState({privacy: false})}/>
							<TC show={tc} close={() => this.setState({tc: false})}/>
							<div className="legal-links" style={{flexDirection: "column", alignItems:"center"}}>
								<p style={{display: "flex", alignItems:"center", backgroundColor: "#999", padding: "0.5rem", color: "white", width: "100%", justifyContent: "center"}}>&copy; {footer.year}&nbsp;
									{footer.website}
									<img src={footer.logo} style={{height: "20px", width: "auto", margin: "0 0.5rem"}} alt="ander5"/>
									<a style={{color: "white"}} rel="noreferrer" target="_blank" href="https://ander5.de">{footer.text}</a></p>
								<ul>
									<li>
										<span className="link" onClick={() => this.setState({tc: true})}>{t('agb')}</span>
									</li>
									<li>
										<span className="link" onClick={() => this.setState({privacy: true})}>{t('datenschutz')}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Contact);
