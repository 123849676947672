import React from "react";
import {withTranslation} from "react-i18next";
import Popup from "./Popup";
import RenderTranslation from "./RenderTranslation";

class Privacy extends React.Component{
	render(){
		const {t} = this.props;
		return (
			<Popup children={RenderTranslation(t('privacy', { returnObjects: true }))} show={this.props.show} close={this.props.close}/>
		)
	}
}

export default withTranslation()(Privacy);
