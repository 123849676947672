import React from "react";

const RenderTranslation = (c) => {
	const keys = Object.keys(c);
	const render = [];
	keys.map((k, i) => {
		const tag = k.split("/")[0];
		switch (tag){
			case "a" :
				render.push(React.createElement(tag, {key: tag+"-"+i, href: c[k].link, target: c[k].target}, c[k].text));
				break;
			case "ul" :
				const lis = c[k].map((li, j) => React.createElement("li", {key: "li-"+i+"-"+j}, li));
				render.push(React.createElement(tag, {key: tag+"-"+i}, lis));
				break;
			default:
				render.push(React.createElement(tag, {key: tag+"-"+i}, c[k]))
				break;
		}
		return true;
	})
	return render;
}
export default RenderTranslation;
