import React from "react";
import Logo from "../assets/img/Logo";
import background from "../assets/video/background.mp4"
import {BsChevronDoubleDown} from "react-icons/bs";

class Welcome extends React.Component{
	render() {
		return (
			<div className="section" id="welcome">
				<video className='videoTag' autoPlay loop muted>
					<source src={background} type='video/mp4' />
				</video>
				<div className="container">
					<div className="logo">
						<Logo />
					</div>
					<div className="line"/>
					<h1><span>real estate</span><span> • projects</span><span> • venture capital</span><span> • startups</span></h1>
					<div className="scroll-down" onClick={() => this.props.scrollDown("contact")}>
						<BsChevronDoubleDown color="white"/>
					</div>
				</div>
			</div>
		)
	}
}
export default Welcome;
