import React from "react";
import {Components} from "./components";
import Scrollbars from "react-custom-scrollbars";
import i18n from "i18next";
import { withTranslation, initReactI18next } from "react-i18next";
import {resources} from "./assets/translations";

i18n.use(initReactI18next).init({
	resources: {...resources},
	lng: "de",
	fallbackLng: "de",
	
	interpolation: {
		escapeValue: false
	}
});

class App extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			scrolled: false,
			visible: "welcome"
		}
	}
	handleScroll(){
		const {scrolled, visible} = this.state;
		if(this.scrollbar.getValues().top > 0 && !scrolled){
			this.setState({
				scrolled: true
			})
		}else if(scrolled && this.scrollbar.getValues().top === 0){
			this.setState({
				scrolled: false
			})
		}
		const sections = document.getElementsByClassName("section");
		let smallest = {
			id: false,
			value: 0
		};
		for(let i = 0; i < sections.length; i++){
			const bounding = sections[i].getBoundingClientRect();
			if(!smallest.id || Math.abs(bounding.top) < smallest.value){
				smallest = {
					value: Math.abs(bounding.top),
					id: sections[i].id
				};
			}
		}
		if(!visible || visible !== smallest.id){
			this.setState({
				visible: smallest.id
			})
		}
	}
	scrollDown(id){
		const element = document.getElementById(id);
		if(element){
			this.scrollbar.view.scroll({
				top: element.offsetTop,
				left: 0,
				behavior: 'smooth'
			});
		}
	}
	render() {
		const {scrolled, visible} = this.state;
		return (
			<>
				<Components.Navigation visible={visible} scrolled={scrolled} scrollDown={id => this.scrollDown(id)}/>
				<div className="scroll-wrapper">
					<Scrollbars ref={e => this.scrollbar = e} onScroll={() => this.handleScroll()} autoHide>
						<div className="main">
							<Components.Welcome scrollDown={id => this.scrollDown(id)}/>
							<Components.Contact/>
						</div>
					</Scrollbars>
				</div>
			</>
		);
	}
}

export default withTranslation()(App);
