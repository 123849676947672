import React from "react";
import Scrollbars from "react-custom-scrollbars";

class Popup extends React.Component{
	render(){
		return (<>
			{this.props.show && <div className="popup">
				<div className="background" onClick={() => this.props.close()}/>
				<div className="inner">
					<Scrollbars autoHeight autoHeightMax={500}>
						{this.props.children && this.props.children}
					</Scrollbars>
				</div>
			</div>}
		</>
		)
	}
}
export default Popup;
