export const resources = {
	de: {
		translation: {
			"name": "Name",
			"email": "E-Mail",
			"subject": "Betreff",
			"phone": "Telefon",
			"message": "Nachricht",
			"contact": "Kontakt",
			"service": "Service",
			"agb": "AGB",
			"datenschutz": "Datenschutz",
			"send": "senden",
			"datapolicy": "Ich bestätige die Datenschutzrichtlinien gelesen und akzeptiert zu haben.",
			"was-tun": "Was können wir für Sie tun?",
			"fill-out": "Füllen Sie so viele Felder wie möglich aus, damit wir uns erfolgreich um Ihr Anliegen kümmern können.",
			"": "missing translation key",
			"get-in-touch": "Nehmen Sie Kontakt mit uns auf und wir werden uns schnellstmöglich bei Ihnen melden. Verwenden Sie hierfür unser Kontaktformular oder eine der unten angegebenen Kommunikationsmöglichkeiten.",
			"privacy": {
				"h3/0":"Datenschutzerklärung",
				"h4/0":"Allgemeiner Hinweis und Pflichtinformationen",
				"p/0":"Benennung der verantwortlichen Stelle",
				"p/1":"Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:",
				"p/2":"m8werk, Christoph Herrmann, Ebersteinstraße 13, 66540 Neunkirchen",
				"p/3":"Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).",
				"h4/1":"Widerruf Ihrer Einwilligung zur Datenverarbeitung",
				"p/4":"Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.",
				"h4/2":"Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde",
				"p/5":"Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:",
				"a/0": {
					"link": "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html",
					"text": "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html",
					"target": "_blank"
				},
				"h4/3":"Recht auf Datenübertragbarkeit",
				"p/6":"Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.",
				"h4/4":"Recht auf Auskunft, Berichtigung, Sperrung, Löschung",
				"p/7":"Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.",
				"h4/5":"SSL- bzw. TLS-Verschlüsselung",
				"p/8":"Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.",
				"h4/6":"Server-Log-Dateien",
				"p/9":"In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:",
				"ul/0":[
					"Browsertyp und Browserversion",
					"Verwendetes Betriebssystem",
					"Referrer URL",
					"Hostname des zugreifenden Rechners",
					"Uhrzeit der Serveranfrage",
					"IP-Adresse"
				],
				"p/10":"Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.",
				"h4/7":"Kontaktformular",
				"p/11":"Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.",
				"p/12":"Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.",
				"p/13":"Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.",
				"h4/8":"Cookies",
				"p/14":"Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. ",
				"p/15":"Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.",
				"p/16":"Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.",
				"p/17":"Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.",
				"h4/9":"Google Analytics",
				"p/18":"Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
				"p/19":"Google Analytics verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen über Ihre Benutzung unserer Website werden an einen Server von Google übermittelt und dort gespeichert. Server-Standort ist im Regelfall die USA.",
				"p/20":"Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir &nbsp;ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu optimieren.",
				"p/21":"IP-Anonymisierung",
				"p/22":"Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA überträgt und dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet keine Zusammenführung der von Google Analytics übermittelten P-Adresse mit anderen Daten von Google statt.",
				"p/23":"Browser Plugin",
				"p/24":"Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das über folgenden Link erreichbare Browser-Plugin herunterladen und installieren:",
				"a/1": {
					"link": "https://tools.google.com/dlpage/gaoptout?hl=de",
					"text": "https://tools.google.com/dlpage/gaoptout?hl=de",
					"target": "_blank"
				},
				"p/25":"Widerspruch gegen die Datenerfassung",
				"p/26":"Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen unserer Website verhindert: Google Analytics deaktivieren.",
				"p/27":"Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:",
				"a/2": {
					"link": "https://support.google.com/analytics/answer/6004245?hl=de",
					"text": "https://support.google.com/analytics/answer/6004245?hl=de",
					"target": "_blank"
				},
				"p/28":"Auftragsverarbeitung",
				"p/29":"Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen Vertrag über die Auftragsverarbeitung abgeschlossen.",
				"p/30":"Demografische Merkmale bei Google Analytics",
				"p/31":"Unsere Website verwendet die Funktion “demografische Merkmale” von Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung” erläutert, generell untersagen.",
			},
			"tc":{
				"h3/0":"Allgemeine Geschäftsbedingungen von m8werk Consulting",
				"p/0":"(nachstehend m8werk genannt)",
				"h4/0":"1. Geltungsbereich",
				"ul/0":[
					"Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle zwischen m8werk und dem Auftraggeber geschlossenen Verträge ausschließlich. Entgegenstehende oder von diesen AGB abweichende Bedingungen werden nicht Vertragsbestandteil, es sei denn, m8werk hätte dere n Geltung ausdrücklich schriftlich",
					"Alle Vereinbarungen, die zwischen m8werk und dem Auftraggeber zwecks Ausführung dieses Vertrags getroffen werden, sind in diesem Vertrag schriftlich",
				],
				"h4/1":"2. Urheberschutz; Nutzungsrechte; Eigenwerbung",
				"ul/1":[
					"Der m8werk erteilte Auftrag ist ein Urheberwerkvertrag. Vertragsgegenstand ist die Schaffung des in Auftrag gegebenen Werkes sowie die Einräumung von Nutzungsrechten an diesem Es gelten die Vorschriften des Werkvertragsrechts und des Urheberrechtsgesetzes.",
					"Sämtliche Arbeiten von m8werk, wie insbesondere Entwürfe, Reinzeichnungen und das in Auftrag gegebene Werk insgesamt, sind als persönlich geistige Schöpfungen durch das Urheberrechtsgesetz geschützt, dessen Regelungen auch dann als vereinbart gelten, wenn die Voraussetzungen für ein urheberrechtlich geschütztes Werk, so insbesondere hinsichtlich der erforderlichen Schöpfungshöhe (§ 2 2 UrhG), nicht erreicht sind.",
					"Ohne Zustimmung von m8werk dürfen deren Arbeiten sowie das Werk einschließlich der Urheberbezeichnung weder im Original, noch bei der Reproduktion geändert Jede Nachahmung des Werkes oder Teilen des Werkes sowie der Vorarbeiten dazu sind unzulässig.",
					"Die Werke von m8werk dürfen nur für die vereinbarte Nutzungsart und den vereinbarten Zweck im vereinbarten Umfang verwendet Mangels ausdrücklicher schriftlicher Vereinbarung gilt als Zweck des Vertrags nur der vom Auftraggeber bei Auftragserteilung erkennbar gemachte Zweck.",
					"m8werk räumt dem Auftraggeber die für den jeweiligen Verwendungszweck (Ziffer 4) erforderlichen Nutzungsrechte ein. Hierzu wird das einfache Nutzungsrecht eingeräumt, es sei denn, Designer und der Auftraggeber treffen eine ausdrücklich abweichende Vereinbarung. Die Einräumung der Nutzungsrechte erfolgt erst mit der vollständigen Bezahlung des Honorars.",
					"Die Übertragung eingeräumter Nutzungsrechte an Dritte bedarf der vorherigen schriftlichen Zustimmung von",
					"Sofern keine anderslautende Vereinbarung getroffen wird, ist m8werk bei der Vervielfältigung, Verbreitung, Ausstellung, in Veröffentlichungen über das Werk und/oder der öffentlichen Wiedergabe der Entwürfe und Reinzeichnungen und des Werkes als Urheber zu Verletzt der Auftraggeber das Recht auf Urheberbenennung kann m8werk zusätzlich zu dem für die Designleistung geschuldeten Honorar eine Vertragsstrafe in Höhe von 100 % des für die Nutzung vereinbarten, mangels einer Vereinbarung des dafür angemessenen und üblichen Honorars verlangen. Hiervon bleibt das Recht von m8werk unberührt, bei einer konkreten Schadensberechnung einen höheren Schaden geltend zu machen.",
					"Vorschläge, Weisungen und Anregungen des Auftraggebers aus technischen, gestalterischen oder anderen Gründen und seine sonstige Mitarbeit haben keinen Einfluss auf das Honorar und begründen kein Miturheberrecht, es sei denn, dass Entgegenstehendes ausdrücklich schriftlich vereinbart wurde.",
					"Der Auftraggeber ist ohne vorherige schriftliche Zustimmung von m8werk nicht berechtigt, in Bezug auf die Entwürfe, Reinzeichnungen oder sonstigen Arbeiten von Designer formale Schutzrechte wie z.B. Geschmacksmuster, Marke etc. zur Eintragung",
					"m8werk bleibt berechtigt, die in Erfüllung des Auftrags geschaffenen Werke oder Teile davon, Entwürfe und sonstige Arbeiten für die Eigenwerbung, gleich in welchem Medium (z.B. in einer eigenen Internetpräsenz, Mustermappe etc.) zu nutzen und auf seine Tätigkeit für den Auftraggeber hinzuweisen.",
				],
				"h4/2":"3. Honorare",
				"ul/2":[
					"Soweit zwischen Auftraggeber und m8werk nicht ausdrücklich etwas anderes vereinbart ist, richtet sich die Berechnung des Honorars nach den Honorarempfehlungen dem AGD (Allianz deutscher Designer) Vergütungstarifvertrag Design (AGD/SDSt) in der Fassung vom April 2011.",
					"Die Anfertigung von Entwürfen ist stets kostenpflichtig, sofern nicht ausdrücklich etwas anderes schriftlich vereinbart",
					"Die Honorare sind bei Ablieferung des Werkes fällig. Erfolgt die Erstellung und Ablieferung des Werkes in Teilen, so ist das entsprechende Teilhonorar jeweils bei Ablieferung des Teils fällig. Sofern nicht ausdrücklich schriftlich etwas anderes vereinbart wird, ist mit der ersten Teillieferung ein Teilhonorar zu zahlen, das wenigstens die Hälfte des Gesamthonorars beträgt. Erstreckt sich die Ausführung eines Auftrags über einen längeren Zeitraum, so kann m8werk Abschlagszahlungen entsprechend dem erbrachten Aufwand",
					"Sämtliche Honorare sind Nettobeträge, zahlbar der gesetzlichen Mehrwertsteuer, ohne Abzug innerhalb von zwei Wochen ab Fälligkeit.",
				],
				"h4/3":"4. Zusatzleistungen; Neben- und Reisekosten",
				"ul/3":[
					"Soweit keine anderslautende schriftliche Vereinbarung getroffen ist, werden Zusatzleistungen, wie z.B. die Recherche, die Umarbeitung oder Änderung von Entwürfen, die Schaffung und Vorlage weiterer Entwürfe, die Änderung von Werkzeichnungen sowie sonstige Zusatzleistungen (Autorenkorrekturen, Produktionsüberwachung und anderes) nach Zeitaufwand gesondert",
					"Im Zusammenhang mit den Entwurfsarbeiten oder mit Entwurfsausführungsarbeiten entstehende technische Nebenkosten (z.B. für Modelle, Zwischenreproduktionen, Layoutsatz etc.) sind vom Auftraggeber zu",
					"Der Auftraggeber erstattet m8werk die Kosten und Spesen für Reisen, die nach vorheriger Abstimmung zwecks Durchführung und Erfüllung des Auftrags oder der Nutzung der Werke erforderlich",
					"Die Vergütung für Zusatzleistungen ist nach deren Erbringung fällig. Verauslagte Nebenkosten sind nach Anfall zu erstatten. Vergütungen und Nebenkosten sind Nettobeträge, die zzgl. der gesetzlichen Mehrwertsteuer zu entrichten",
				],
				"h4/4":"5. Fremdleistungen",
				"ul/4":[
					"Die Vergabe von Fremdleistungen, die für die Erfüllung des Auftrags oder die Nutzung der Werke im vertragsgemäßen Umfang erforderlich sind, nimmt m8werk im Namen und für Rechnung des Auftraggebers vor. Der Auftraggeber ist verpflichtet, m8werk hierzu die entspreche nde schriftliche Vollmacht zu",
					"Soweit m8werk auf Veranlassung des Auftraggebers im Einzelfall Fremdleistungen im eigenen Namen und auf eigene Rechnung vergibt, ist der Auftraggeber verpflichtet, einen angemessen Vorschuss für die zu erwartenden Kosten zu zahlen. Der Auftraggeber stellt m8werk im Innenverhältnis von sämtlichen Verbindlichkeiten, insbesondere sämtlichen Kosten, frei, die sich aus dem Vertragsabschluss",
				],
				"h4/5":"6. Mitwirkung des Auftraggebers; Gestaltungsfreiheit; Vorlagen",
				"ul/5":[
					"Der Auftraggeber ist verpflichtet, m8werk alle Unterlagen, die für die Erfüllung des Auftrags notwendig sind, rechtzeitig und im vereinbarten Umfange zur Verfügung zu stellen. Dies betrifft insbesondere Texte, Fotos, Logos, Grafiken, Filme, Musikstücke etc. Verzögerungen bei der Auftragsausführung, die auf die verspätete oder nicht vollständige Übergabe solcher Unterlagen beruhen, hat m8werk nicht zu",
					"Der Auftraggeber versichert, zur Nutzung aller Unterlagen, die er m8werk zur Verfügung stellt, berechtigt zu sein. Der Auftraggeber ist ferner alleine verantwortlich für die Richtigkeit und Vollständigkeit der von ihm gestellten Unterlagen. Sollte der Auftraggeber nicht zur Nutzung berechtigt sein oder sollten die Vorlagen nicht frei von Rechten Dritter sein, so stellt der Auftraggeber m8werk im Innenverhältnis von allen Ersatzansprüchen Dritter",
					"Für m8werk besteht im Rahmen des Auftrags Gestaltungsfreiheit. In diesem Umfang sind Beanstandungen hinsichtlich der künstlerischen Gestaltung der Entwürfe und des Werkes ausgeschlossen. Mehrkosten für Änderungen, die der Auftraggeber während oder nach der Produktion veranlasst, trägt der",
				],
				"h4/6":"7. Datenlieferung und Handling",
				"ul/6":[
					"m8werk ist nicht verpflichtet, die Designdaten oder sonstige Daten (z.B. Daten von Inhalten, Screendesigns, Entwürfen usw.) oder Datenträger, die in Erfüllung des Auftrages entstanden sind, an den Auftraggeber herauszugeben. Wünscht der Auftraggeber die Herausgabe von Daten oder Dateien, so ist dies gesondert zu vereinbaren und vom Auftraggeber zu vergüten.",
					"Stellt Designer dem Auftraggeber Dateien bzw. Daten zur Verfügung, so dürfen diese nur im vereinbarten Umfang genutzt werden. Modifikationen oder Veränderungen an den Dateien Daten dürfen nur mit schriftlicher Zustimmung von m8werk vorgenommen werden.",
					"Gefahr und Kosten des Transports von Datenträgern, Dateien und Daten trägt unabhängig vom Übermittlungsweg der",
					"Für Mängel an Datenträgern, Dateien und Daten, die bei der Datenübertragung auf das System des Auftraggebers entstehen, haftet m8werk",
				],
				"h4/7":"8. Eigentum und Rückgabepflicht",
				"ul/7":[
					"An allen Entwürfen, Reinzeichnungen und Konzeptionsleistungen sowie etwaig zur Verfügung gestellter m8werk, gleichgültig ob sie zur Ausführung gelangen oder nicht, werden lediglich Nutzungsrechte eingeräumt, nicht jedoch Eigentumsrechte übertragen. Originale sind, spätestens drei Monate nach Lieferung unbeschädigt an m8werk zurückzugeben, falls nicht ausdrücklich eine andere Vereinbarung getroffen",
					"Die Zu- und Rücksendungen erfolgen auf Gefahr und für Rechnung des Auftraggebers. Bei Beschädigung oder Verlust hat der Auftraggeber die Kosten zu ersetzen, die zur Wiederherstellung der Originale notwendig sind. m8werk bleibt vorbehalten, darüber hinaus einen weitergehenden Schaden geltend zu",
				],
				"h4/8":"9. Korrektur; Produktionsüberwachung; Belegmuster",
				"ul/8":[
					"Vor Beginn der Vervielfältigung des Werkes (Produktionsbeginn) sind m8werk Korrekturmuster",
					"Die Produktion wird von m8werk nur überwacht, wenn dies in einer gesonderten schriftlichen Vereinbarung mit dem Auftraggeber vereinbart Für diesen Fall ist m8werk berechtigt, erforderliche Entscheidungen nach eigenem Ermessen zu treffen und Weisungen gegenüber den Produktionsfirmen zu geben. m8werk haftet für Fehler nur bei eigenem Verschulden und nach Maßgabe der Ziffer 10.",
					"Von allen vervielfältigten Werken oder Teilen der Werke oder sonstigen Arbeiten sind m8werk eine angemessene Anzahl einwandfreier Belegexemplare, mindestens 10 Stück unentgeltlich zu überlassen, die m8werk auch im Rahmen seiner Eigenwerbung verwenden",
				],
				"h4/9":"10. Gewährleistung; Haftung",
				"ul/9":[
					"m8werk haftet für Schäden nur bei Vorsatz oder grober Fahrlässigkeit. Davon ausgenommen sind Schäden aus der Verletzung einer Vertragspflicht, die für die Erreichung des Vertragszwecks von wesentlicher Bedeutung ist (Kardinalpflicht), sowie Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, für welche m8werk auch bei leichter Fahrlässigkeit",
					"Ansprüche des Auftraggebers gegen m8werk aufgrund einer Pflichtverletzung verjähren ein Jahr nach dem gesetzlichen Verjährungsbeginn. Davon ausgenommen sind Schadensersatzansprüche gemäß Ziffer 10.1; für diese gelten die gesetzlichen Verjährungsfristen.",
					"Der Auftraggeber ist verpflichtet, das Werk unverzüglich nach Ablieferung zu untersuchen und etwaige Mängel unverzüglich anzuzeigen. Offensichtliche Mängel müssen spätestens binnen zwei Wochen nach Ablieferung schriftlich geltend gemacht werden. Zur Wahrung der Frist genügt die rechtzeitige Absendung der Mängelrüge. Bei Verletzung der Untersuchungs- und Rügepflicht gilt das Werk als mangelfrei",
					"Die Freigabe von Produktion und Veröffentlichung erfolgt durch den Auftraggeber. Mit der Freigabe übernimmt der Auftraggeber die Haftung für die technische und funktionsgemäße Richtigkeit von Text, Bild, Gestaltung und",
					"Mit Ausnahme eines möglichen Auswahlverschuldens haftet m8werk nicht für Aufträge für Fremdleistungen, die Designer an Dritte",
					"Sofern m8werk Fremdleistungen auf Veranlassung des Auftraggebers im eigenen Namen und auf eigene Rechnung vergibt, tritt m8werk hiermit sämtliche ihm zustehenden Gewährleistungs-, Schadensersatz- und sonstigen Ansprüche aus fehlerhafter, verspäteter oder Nichterfüllung gegenüber der Fremdfirma an den Auftraggeber ab. Der Auftraggeber verpflichtet sich, vor einer Inanspruchnahme von m8werk zunächst, die abgetretenen Ansprüche gegenüber der Fremdfirma",
					"m8werk haftet nicht für die urheber-, geschmacksmuster- oder markenrechtliche Schutz- oder Eintragungsfähigkeit des Werkes oder Teilen des Werkes sowie der Entwürfe oder seiner sonstigen Designarbeiten, die er dem Auftraggeber zur Nutzung überlässt. m8werk ist nicht verpflichtet, Geschmacksmuster-, Marken- oder sonstige Schutzrechtsrecherchen durchzuführen oder zu veranlassen. Diese sowie eine Überprüfung der Schutzrechtslage werden vom Auftraggeber selbst und auf eigene Kosten",
					"m8werk haftet nicht für die rechtliche, insbesondere die urheber-, geschmacksmuster-, wettbewerbs- oder markenrechtliche Zulässigkeit der vorgesehenen Nutzung des Werkes oder von Teilen des Werkes oder der Entwürfe. m8werk ist lediglich verpflichtet, auf rechtliche Risiken hinzuweisen, soweit diese m8werk bei der Durchführung des Auftrags bekannt",
				],
				"h4/10":"11. Erfüllungsort",
				"p/1":"Erfüllungsort für beide Parteien ist Neunkirchen.",
				"h4/11":"12. Schlussbestimmungen",
				"ul/10":[
					"Gerichtsstand ist Neunkirchen, sofern der Auftraggeber Kaufmann ist und der Vertrag zum Betrieb seines Handelsgewerbes gehört oder der Auftraggeber juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist oder keinen allgemeinen Gerichtsstand in Deutschland hat. m8werk ist auch berechtigt, am Sitz des Auftraggebers zu",
					"Es gilt das Recht der Bundesrepublik Deutschland mit Ausnahme des UN-Kaufrechts.",
					"Ist eine der Bestimmungen dieser AGB ganz oder teilweise unwirksam, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt.",
				],
				"p/99": "",
				"p/2":"Stand: 30.07.2021 © m8werk.de",
			}
		}
	},
	en: {
		translation: {
			"name": "Name",
			"email": "E-Mail",
			"subject": "Subject",
			"phone": "Phone",
			"message": "Message",
			"contact": "Contact",
			"service": "Service",
			"agb": "T&C",
			"datenschutz": "Privacy policy",
			"send": "send",
			"datapolicy": "I confirm that I have read and accepted the privacy policy.",
			"was-tun": "What can we do for you?",
			"fill-out": "Fill in as many fields as possible so that we can successfully take care of your request.",
			"": "missing translation key",
			"get-in-touch": "Contact us and we will get back to you as soon as possible. To do this, use our contact form or one of the communication options below.",
			"privacy": {
				"h3/0": "Privacy policy",
				"h4/0": "General notice and mandatory information",
				"p/0": "Designation of the responsible entity",
				"p/1": "The data controller for this website is:",
				"p/2": "m8werk, Christoph Herrmann, Ebersteinstraße 13, 66540 Neunkirchen",
				"p/3": "The controller alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, contact details or similar).",
				"h4/1": "Revocation of your consent to data processing",
				"p/4": "Only with your explicit consent are some data processing operations possible. A revocation of your already given consent is possible at any time. An informal communication by e-mail is sufficient for the revocation. The legality of the data processing carried out until the revocation remains unaffected by the revocation.",
				"h4/2": "Right to lodge a complaint with the competent supervisory authority",
				"p/5": "As a data subject, you have a right of appeal to the competent supervisory authority in the event of a data protection breach. The competent supervisory authority with regard to data protection issues is the state data protection commissioner of the federal state in which our company's registered office is located. The following link provides a list of data protection officers and their contact details:",
				"a/0": {
					"link": "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html",
					"text": "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html",
					"target": "_blank"
				},
				"h4/3": "Right to data portability",
				"p/6": "You have the right to have data that we process automatically on the basis of your consent or in performance of a contract handed over to you or to third parties. The data shall be provided in a machine-readable format. If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.",
				"h4/4": "Right of access, rectification, blocking, erasure",
				"p/7": "Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, the origin of the data, their recipients and the purpose of the data processing and, if applicable, a right to correction, blocking or deletion of this data. In this regard, and also for further questions on the subject of personal data, you can contact us at any time via the contact options listed in the imprint.",
				"h4/5": "SSL or TLS encryption",
				"p/8": "For security reasons and to protect the transmission of confidential content that you send to us as the site operator, our website uses SSL or TLS encryption. This means that data you transmit via this website cannot be read by third parties. You can recognize an encrypted connection by the \"https://\" address line of your browser and by the lock symbol in the browser line.",
				"h4/6": "server log files",
				"p/9": "In server log files, the provider of the website automatically collects and stores information that your browser automatically transmits to us. These are:",
				"ul/0":[
					"Browser type and version",
					"Operating System Used",
					"referrer URL",
					"hostname of accessing computer",
					"time of server request",
					"IP address"
				],
				"p/10": "There is no merging of this data with other data sources. The data processing is based on Art. 6 (1) lit. b DSGVO, which permits the processing of data for the performance of a contract or pre-contractual measures.",
				"h4/7": "Contact form",
				"p/11": "Data transmitted via contact form will be stored, including your contact data, in order to be able to process your inquiry or to be available for follow-up questions. This data will not be passed on without your consent.",
				"p/12": "The processing of the data entered in the contact form is based exclusively on your consent (Art. 6 para. 1 lit. a DSGVO). A revocation of your already given consent is possible at any time. An informal communication by e-mail is sufficient for the revocation. The legality of the data processing operations carried out until the revocation remains unaffected by the revocation.",
				"p/13": "Data transmitted via the contact form will remain with us until you request us to delete it, revoke your consent to store it or there is no longer any need to store it. Mandatory legal provisions - in particular retention periods - remain unaffected.",
				"h4/8": "cookies",
				"p/14": "Our website uses cookies. These are small text files that your web browser stores on your terminal device. Cookies help us to make our offer more user-friendly, effective and secure. ",
				"p/15": "Some cookies are \"session cookies.\" Such cookies are deleted by themselves after the end of your browser session. In contrast, other cookies remain on your terminal device until you delete them yourself. Such cookies help us to recognize you when you return to our website.",
				"p/16": "With a modern web browser, you can monitor, restrict or disable the setting of cookies. Many web browsers can be configured to automatically delete cookies when you close the program. Disabling cookies may result in limited functionality of our website.",
				"p/17": "The setting of cookies, which are necessary for the exercise of electronic communication processes or the provision of certain functions desired by you (e.g. shopping cart), is based on Art. 6 (1) lit. f DSGVO. As the operator of this website, we have a legitimate interest in storing cookies for the technically error-free and smooth provision of our services. Insofar as the setting of other cookies (e.g. for analysis functions) takes place, these are treated separately in this data protection declaration.",
				"h4/9": "Google Analytics",
				"p/18": "Our website uses functions of the web analytics service Google Analytics. The provider of the web analytics service is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
				"p/19": "Google Analytics uses cookies. These are small text files that your web browser stores on your terminal device and enable an analysis of website usage. Information generated by means of cookies about your use of our website is transmitted to a Google server and stored there. The server location is usually the USA.",
				"p/20": "The setting of Google Analytics cookies is based on Art. 6 (1) lit. f DSGVO. As the operator of this website, we &nbsp;have a legitimate interest in analyzing user behavior in order to optimize our web offering and, if necessary, advertising.",
				"p/21": "IP anonymization",
				"p/22": "We use Google Analytics in conjunction with the IP anonymization function. It ensures that Google truncates your IP address within member states of the European Union or in other states party to the Agreement on the European Economic Area before transmitting it to the USA. There may be exceptional cases in which Google transfers the full IP address to a server in the USA and shortens it there. On our behalf, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing us with other services relating to website activity and internet usage. There is no merging of the p address transmitted by Google Analytics with other data from Google.",
				"p/23": "Browser Plugin",
				"p/24": "The setting of cookies by your web browser can be prevented. However, some functions of our website could be restricted as a result. You can also prevent the collection of data relating to your website use, including your IP address, and subsequent processing by Google. This is possible by downloading and installing the browser plugin accessible via the following link:",
				"a/1": {
					"link": "https://tools.google.com/dlpage/gaoptout?hl=de",
					"text": "https://tools.google.com/dlpage/gaoptout?hl=de",
					"target": "_blank"
				},
				"p/25": "Object to data collection",
				"p/26": "You can prevent the collection of your data by Google Analytics by clicking on the following link. An opt-out cookie will be set that will prevent the collection of your data during future visits to our website: Disable Google Analytics.",
				"p/27": "For details on how Google Analytics handles user data, see Google's privacy policy:",
				"a/2": {
					"link": "https://support.google.com/analytics/answer/6004245?hl=de",
					"text": "https://support.google.com/analytics/answer/6004245?hl=de",
					"target": "_blank"
				},
				"p/28": "Order processing",
				"p/29": "To fully comply with legal data protection requirements, we have entered into an order processing agreement with Google.",
				"p/30": "Demographic characteristics in Google Analytics",
				"p/31": "Our website uses the \"demographic characteristics\" feature of Google Analytics. It can be used to create reports that contain statements about the age, gender and interests of site visitors. This data comes from interest-based advertising from Google as well as visitor data from third-party providers. It is not possible to assign the data to a specific person. You can deactivate this function at any time. This is possible via the ad settings in your Google account or by generally prohibiting the collection of your data by Google Analytics, as explained in the item \"Objection to data collection.\"",
			},
			"tc":{
				"h3/0": "General Terms and Conditions of m8werk Consulting",
				"p/0":"(hereinafter referred to as m8werk)",
				"h4/0": "1. Scope of application",
				"ul/0":[
					"These General Terms and Conditions (GTC) shall apply exclusively to all contracts concluded between m8werk and the client. Conflicting terms and conditions or terms and conditions deviating from these GTC shall not become part of the contract unless m8werk has expressly agreed to their validity in writing.",
					"All agreements made between m8werk and the client for the purpose of executing this contract shall be in writing in this contract",
				],
				"h4/1": "2. Copyright protection; rights of use; self-promotion",
				"ul/1":[
					"The order placed with m8werk is a copyright contract. The subject matter of the contract is the creation of the commissioned work as well as the granting of rights of use to this It is subject to the provisions of the law on contracts for work and services and the copyright law.",
					"All works of m8werk, such as, in particular, drafts, final artwork and the commissioned work as a whole, are protected as personal intellectual creations by the Copyright Act, the provisions of which shall be deemed agreed upon even if the requirements for a work protected by copyright, such as, in particular, with regard to the required level of creation (§ 2 2 UrhG), have not been met.",
					"Without the consent of m8werk, their works as well as the work including the author's designation may not be changed, neither in the original, nor in the reproduction Any imitation of the work or parts of the work as well as the preparatory work thereto shall be inadmissible.",
					"The works of m8werk may only be used for the agreed type of use and the agreed purpose to the agreed extent In the absence of an express written agreement, the purpose of the contract shall only be the purpose made apparent by the client when the order was placed.",
					"m8werk shall grant the client the rights of use required for the respective purpose of use (clause 4). For this purpose, the simple right of use shall be granted, unless the Designer and the Principal expressly agree otherwise. The granting of the rights of use shall not take place until the fee has been paid in full.",
					"The transfer of granted rights of use to third parties requires the prior written consent of",
					"Unless otherwise agreed, m8werk shall be entitled to be named as the author in the reproduction, distribution, exhibition, in publications about the work and/or the public reproduction of the designs and final artwork and the work.", "If the client violates the right to be named as the author, m8werk may, in addition to the fee owed for the design service, demand a contractual penalty in the amount of 100% of the fee agreed for the use or, in the absence of an agreement, of the reasonable and customary fee for this. This shall not affect m8werk's right to claim higher damages in the event of a concrete calculation of damages.",
					"Proposals, instructions and suggestions of the client for technical, design or other reasons and his other cooperation shall have no influence on the fee and shall not constitute a joint copyright, unless the contrary has been expressly agreed in writing.",
					"The Client shall not be entitled to register any formal property rights such as design patents, trademarks, etc. in relation to the designs, final artwork or other work of Designer without the prior written consent of m8werk",
					"m8werk remains entitled to use the works or parts thereof, designs and other works created in fulfillment of the order for its own advertising, regardless of the medium (e.g. in its own Internet presence, sample folder, etc.) and to refer to its activities for the client."
				],
				"h4/2": "3. Fees",
				"ul/2":[
					"Unless otherwise expressly agreed between the client and m8werk, the calculation of the fee shall be based on the fee recommendations of the AGD (Alliance of German Designers) design remuneration agreement (AGD/SDSt) as amended in April 2011.",
					"The preparation of designs is always subject to a fee, unless expressly agreed otherwise in writing.",
					"Fees are due upon delivery of the work. If the work is created and delivered in parts, the corresponding partial fee shall be due upon delivery of the respective part. Unless expressly agreed otherwise in writing, a partial fee of at least half of the total fee shall be payable with the first partial delivery. If the execution of an order extends over a longer period of time, m8werk may make partial payments in accordance with the work performed.",
					"All fees are net amounts, payable of the statutory value added tax, without deduction within two weeks from the due date."
				],
				"h4/3": "4. Additional services; incidental and travel expenses",
				"ul/3":[
					"Unless otherwise agreed in writing, additional services, such as research, reworking or modification of drafts, creation and submission of further drafts, modification of working drawings and other additional services (author's corrections, production supervision and others) shall be charged separately on a time basis.",
					"Incidental technical costs incurred in connection with the design work or with design execution work (e.g. for models, intermediate reproductions, layout typesetting, etc.) shall be borne by the client",
					"The client shall reimburse m8werk for the costs and expenses for travels that are necessary after prior coordination for the purpose of execution and fulfillment of the order or the use of the works",
					"The remuneration for additional services shall be due after their performance. Disbursed additional costs are to be reimbursed as incurred. Remuneration and ancillary costs are net amounts payable plus the statutory value added tax",
				],
				"h4/4": "5. Third-party services",
				"ul/4":[
					"The commissioning of third-party services that are necessary for the fulfillment of the order or the use of the works to the extent stipulated in the contract shall be performed by m8werk on behalf and for the account of the client. The Customer shall be obligated to provide m8werk with the corresponding written power of attorney for this purpose.",
					"Insofar as m8werk, at the instigation of the Client, contracts out external services in its own name and for its own account in individual cases, the Client shall be obligated to pay a reasonable advance payment for the costs to be expected. The client shall indemnify m8werk in the internal relationship from all liabilities, in particular all costs, resulting from the conclusion of the contract",
				],
				"h4/5": "6. Participation of the client; freedom of design; templates",
				"ul/5":[
					"The client shall be obligated to provide m8werk with all documents necessary for the fulfillment of the order in due time and to the extent agreed upon. This applies in particular to texts, photos, logos, graphics, films, pieces of music, etc. Delays in the execution of the order, which are based on the delayed or incomplete handover of such documents, shall not be the responsibility of m8werk.",
					"The client assures that it is entitled to use all documents that it provides to m8werk. Furthermore, the client shall be solely responsible for the correctness and completeness of the documents provided by it. Should the client not be entitled to use them or should the documents not be free of third party rights, the client shall indemnify m8werk internally against all third party claims for compensation.",
					"For m8werk, there is freedom of design within the scope of the order. To this extent, complaints regarding the artistic design of the drafts and the work are excluded. Additional costs for changes initiated by the client during or after production shall be borne by the",
				],
				"h4/6": "7. Data delivery and handling",
				"ul/6":[
					"m8werk shall not be obliged to surrender to the Client the design data or other data (e.g. data of content, screen designs, drafts, etc.) or data carriers created in the performance of the order. If the Client desires the surrender of data or files, this shall be agreed separately and shall be remunerated by the Client.",
					"If Designer makes files or data available to the Client, these may only be used to the extent agreed. Modifications or changes to the files data may only be made with the written consent of m8werk.",
					"The risk and costs of the transport of data carriers, files and data shall be borne by the, irrespective of the transmission channel",
					"m8werk shall be liable for defects of data carriers, files and data that occur during data transmission to the system of the customer."
				],
				"h4/7": "8. Ownership and obligation to return",
				"ul/7":[
					"For all drafts, final artwork and conceptual services as well as any m8werk provided, regardless of whether they are executed or not, only rights of use shall be granted, but no ownership rights shall be transferred. Originals shall be returned to m8werk undamaged no later than three months after delivery, unless expressly agreed otherwise.",
					"Deliveries and returns shall be made at the risk and for the account of the client. In case of damage or loss, the customer shall reimburse the costs necessary to restore the originals. m8werk reserves the right to claim further damages",
				],
				"h4/8": "9. Correction; production supervision; sample of documents",
				"ul/8":[
					"Prior to the commencement of the reproduction of the work (start of production), m8werk shall be provided with proof samples",
					"The production shall only be supervised by m8werk if this is agreed in a separate written agreement with the client In this case, m8werk shall be entitled to make necessary decisions at its own discretion and to issue instructions to the production companies. m8werk shall only be liable for errors in the event of its own fault and in accordance with clause 10.",
					"An appropriate number of perfect specimen copies, at least 10 copies, of all reproduced works or parts thereof or other works shall be provided to m8werk free of charge, which shall also be used by m8werk in the context of its self-promotion."
				],
				"h4/9": "10. Warranty; liability",
				"ul/9":[
					"m8werk shall be liable for damages only in case of intent or gross negligence. Excluded from this are damages from the violation of a contractual obligation that is essential for the achievement of the purpose of the contract (cardinal obligation), as well as damages from injury to life, body or health, for which m8werk shall also be liable in case of slight negligence.",
					"Claims of the client against m8werk due to a breach of duty shall become statute-barred one year after the statutory commencement of the limitation period. Excluded from this are claims for damages according to clause 10.1; for these, the statutory limitation periods shall apply.",
					"The client is obliged to inspect the work immediately after delivery and to report any defects without delay. Obvious defects must be notified in writing within two weeks of delivery at the latest. The timely dispatch of the notice of defect shall be sufficient to comply with the time limit. In the event of a breach of the obligation to inspect and give notice of defects, the work shall be deemed to be free of defects.",
					"The release of production and publication shall be effected by the client. With the release, the client assumes liability for the technical and functional correctness of text, image, design and",
					"With the exception of a possible selection fault, m8werk is not liable for orders for third-party services that designers to third parties",
					"Insofar as m8werk awards third-party services at the instigation of the client in its own name and for its own account, m8werk hereby assigns to the client all warranty claims, claims for damages and other claims to which it is entitled arising from faulty, delayed or non-performance vis-à-vis the third-party company. The client undertakes, prior to any recourse against m8werk, first to assert the assigned claims against the outside company.",
					"m8werk shall not be liable for the protectability or registrability under copyright, design patent or trademark law of the work or parts of the work as well as the drafts or its other design work, which it leaves to the client for use. m8werk shall not be obligated to conduct or arrange for any research on design patent, trademark or other property rights. These as well as a review of the property right situation shall be carried out by the client itself and at its own expense.",
					"m8werk shall not be liable for the legal admissibility, in particular the admissibility under copyright, design patent, competition or trademark law, of the intended use of the work or of parts of the work or of the drafts. m8werk shall only be obliged to point out legal risks, as far as these are known to m8werk during the execution of the order."
				],
				"h4/10": "11. Place of performance",
				"p/1": "Place of performance for both parties shall be Neunkirchen.",
				"h4/11": "12. Final Provisions",
				"ul/10":[
					"The place of jurisdiction shall be Neunkirchen, provided that the client is a merchant and the contract is part of the operation of its commercial business or the client is a legal entity under public law or a special fund under public law or has no general place of jurisdiction in Germany. m8werk shall also be entitled to bring an action at the registered office of the client.",
					"The law of the Federal Republic of Germany shall apply with the exception of the UN Convention on Contracts for the International Sale of Goods",
					"If one of the provisions of these GTC is invalid in whole or in part, the validity of the remaining provisions shall remain unaffected."
				],
				"p/99": "",
				"p/2": "As of: 07/30/2021 © m8werk.de",
			}
		}
	}
}
