import ander5 from "./ander5.png";
import m8werk from "./m8werk_group.png";
import consulting from "./consulting_small.png";
import elapporate from "./elapporate.png";
import turnworks from "./Turnworks_Group.png";
import immosaar from "./immosaar24.png";

const images = {
	ander5: ander5,
	m8werk: m8werk,
	consulting: consulting,
	elapporate: elapporate,
	turnworks: turnworks,
	immosaar: immosaar
}

export default images;
